import {
  Button,
  Card,
  CardContent,
  Router,
  Select,
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectSlottedTrigger,
  SelectValue,
  TextField
} from '@/Components'
import { GetStartedLayout } from '@/Layouts/GetStartedLayout'
import { useForm, usePage } from '@inertiajs/react'
import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
  parsePhoneNumber
} from 'libphonenumber-js'
import {
  FormEventHandler,
  ReactElement,
  useCallback,
  type ChangeEventHandler
} from 'react'

type Props = {
  happyPay: boolean
}

Page.layout = (page: ReactElement) => (
  <GetStartedLayout title='Start'>{page}</GetStartedLayout>
)

export default function Page() {
  const { happyPay } = usePage<Props>().props
  const { data, setData, setError, clearErrors, post, processing, errors } =
    useForm('login-form', {
      e164: '',
      phone_number: '+27',
      country: 'ZA'
    })

  const _onChangeInput = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      clearErrors()
      const formatter = new AsYouType(data.country as CountryCode)
      formatter.input(event.target.value)

      let country = data.country

      // Update country code if the user typed in a different CountryCode
      if (
        typeof formatter.country !== 'undefined' &&
        country != formatter.country
      ) {
        country =
          countries.find((country: Country) => country.id == formatter.country)
            ?.id || 'ZA'
      }

      let e164 = data.e164

      try {
        e164 = parsePhoneNumber(
          event.target.value,
          country as CountryCode
        ).number
      } catch (e) {
        // We don't care about the error state here
        e164 = data.e164
      }

      setData({
        e164,
        phone_number: event.target.value,
        country: country
      })
    },
    [clearErrors, data.country, data.e164, setData]
  )

  const _onChangeCountry = useCallback(
    (newCountry: string) => {
      let currentNumber = data.phone_number

      // Only change the country calling code if one already exists
      if (currentNumber.startsWith('+')) {
        currentNumber = currentNumber.replace(
          `+${getCountryCallingCode(data.country as CountryCode)}`,
          `+${getCountryCallingCode(newCountry as CountryCode)}`
        )
      }

      setData({
        e164: data.e164,
        phone_number: currentNumber,
        country: newCountry
      })
    },
    [data.country, data.e164, data.phone_number, setData]
  )

  const submit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault()

      if (data.country == 'ZA') {
        post(route('login'), { preserveState: true })
      } else {
        setError(
          'e164',
          'We currently only support South African mobile numbers.'
        )
      }
    },
    [data.country, post, setError]
  )

  return (
    <>
      <form id='login-form' onSubmit={submit} className='hidden' />
      <Card>
        {happyPay && (
          <div className='flex w-full items-center justify-center gap-4 rounded-xl bg-[#FFE05D] p-4'>
            <svg
              className='flex-none'
              width='43'
              height='44'
              viewBox='0 0 43 44'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M17.9255 12.3681C18.5327 12.7124 19.0225 13.2309 19.3316 13.8567C19.6687 14.512 19.837 15.2989 19.8364 16.2174V22.1893H17.2642V16.5649C17.2642 15.7565 17.0621 15.1347 16.6579 14.6995C16.2537 14.2644 15.7024 14.0471 15.0041 14.0476C14.2937 14.0476 13.7333 14.2649 13.3228 14.6995C12.9123 15.1341 12.707 15.7559 12.707 16.5649V22.1875H10.1348V8.59009H12.707V13.2762C13.0529 12.8234 13.5084 12.4662 14.0306 12.2382C14.6084 11.9812 15.2351 11.8524 15.8675 11.8607C16.586 11.8478 17.2954 12.0227 17.9255 12.3681Z'
                fill='#191C21'
              />
              <path
                d='M26.2226 12.3112C26.8376 11.9909 27.5229 11.8298 28.2161 11.8425C29.048 11.8319 29.8661 12.0547 30.5777 12.4858C31.2945 12.9277 31.8738 13.5609 32.2504 14.3141C32.6603 15.1042 32.8656 16.0198 32.8661 17.061C32.8667 18.1021 32.6615 19.0241 32.2504 19.8267C31.8753 20.5902 31.2968 21.2354 30.5785 21.6912C29.871 22.1335 29.0513 22.3633 28.217 22.3534C27.5295 22.3679 26.8495 22.2097 26.239 21.8933C25.6994 21.6118 25.227 21.2169 24.8544 20.7358V27.0387H22.2812V12.0076H24.8544V13.4782C25.2135 12.9881 25.682 12.5885 26.2226 12.3112ZM29.8613 15.4717C29.6313 15.0464 29.2877 14.6933 28.8689 14.4517C28.463 14.2207 28.0037 14.1003 27.5367 14.1026C27.0744 14.1016 26.6204 14.2255 26.2226 14.4612C25.8028 14.7095 25.4594 15.0687 25.2302 15.4992C24.9825 15.9957 24.8535 16.5431 24.8535 17.098C24.8535 17.6529 24.9825 18.2002 25.2302 18.6967C25.4598 19.127 25.8031 19.486 26.2226 19.7347C26.6239 19.9711 27.0814 20.0949 27.547 20.0933C28.0127 20.0916 28.4693 19.9645 28.8689 19.7253C29.2876 19.4714 29.6304 19.1096 29.8613 18.6778C30.1113 18.2243 30.2368 17.6853 30.238 17.061C30.2518 16.5076 30.1221 15.96 29.8613 15.4717Z'
                fill='#191C21'
              />
              <path
                d='M20.6184 35.4101C16.1258 35.4101 11.9513 33.5507 8.86564 30.1744C8.63756 29.9254 8.51771 29.5961 8.53247 29.2587C8.54723 28.9214 8.69538 28.6038 8.94433 28.3757C9.19329 28.1476 9.52266 28.0278 9.85998 28.0425C10.1973 28.0573 10.5149 28.2054 10.743 28.4544C13.3411 31.2975 16.849 32.8627 20.6184 32.8627C25.2959 32.8627 29.5925 30.4117 32.1114 26.3052C32.1978 26.1609 32.312 26.0351 32.4473 25.9351C32.5826 25.8351 32.7364 25.763 32.8998 25.7229C33.0632 25.6828 33.233 25.6756 33.3992 25.7015C33.5654 25.7275 33.7248 25.7861 33.8682 25.8741C34.0116 25.9621 34.1361 26.0777 34.2346 26.2141C34.333 26.3506 34.4034 26.5052 34.4416 26.669C34.4799 26.8329 34.4852 27.0027 34.4574 27.1686C34.4296 27.3345 34.3691 27.4933 34.2795 27.6357C31.2944 32.505 26.1869 35.4101 20.6184 35.4101Z'
                fill='#191C21'
              />
            </svg>
            <p className='text-sm text-strong'>
              Happiness is a <span className='font-medium'>R 100 boost</span> 🚀
              on your first investment contribution, plus a chance to{' '}
              <span className='font-medium'>win R 5000</span> just for signing
              up. Read the{' '}
              <Router
                href={route('marketing.competition-rules')}
                className='font-medium text-primary'
              >
                competition rules
              </Router>{' '}
              for more details.
            </p>
          </div>
        )}

        <CardContent>
          Enter your mobile number to log in or sign up.
        </CardContent>
        <Select value={data.country} onValueChange={_onChangeCountry}>
          <TextField
            id='phone'
            required
            label='Mobile number'
            name='phone'
            type='tel'
            form='login-form'
            prefixSlot={
              <SelectSlottedTrigger>
                <label htmlFor='currency-code' className='sr-only'>
                  Country
                </label>
                <SelectValue asChild id='currency-code' placeholder='Country'>
                  <span className={`flag:${data.country}`} />
                </SelectValue>
              </SelectSlottedTrigger>
            }
            value={data.phone_number}
            onChange={_onChangeInput}
            errorMessage={errors.e164}
          />

          <SelectContent align='start'>
            {countries.map((currency) => (
              <SelectItem key={currency.id} value={currency.id}>
                <SelectItemText>{currency.name}</SelectItemText>
                <SelectItemIndicator />
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </Card>
      <Button type='submit' form='login-form' disabled={processing}>
        Continue
      </Button>
    </>
  )
}

type Country = {
  id: CountryCode
  name: string
}

const countries: Country[] = [
  {
    id: 'ZA',
    name: 'South Africa'
  },
  {
    id: 'AF',
    name: 'Afghanistan'
  },
  {
    id: 'AL',
    name: 'Albania'
  },
  {
    id: 'DZ',
    name: 'Algeria'
  },
  {
    id: 'AS',
    name: 'American Samoa'
  },
  {
    id: 'AD',
    name: 'Andorra'
  },
  {
    id: 'AO',
    name: 'Angola'
  },
  {
    id: 'AI',
    name: 'Anguilla'
  },
  {
    id: 'AG',
    name: 'Antigua and Barbuda'
  },
  {
    id: 'AR',
    name: 'Argentina'
  },
  {
    id: 'AM',
    name: 'Armenia'
  },
  {
    id: 'AW',
    name: 'Aruba'
  },
  {
    id: 'AU',
    name: 'Australia'
  },
  {
    id: 'AT',
    name: 'Austria'
  },
  {
    id: 'AZ',
    name: 'Azerbaijan'
  },
  {
    id: 'BS',
    name: 'Bahamas'
  },
  {
    id: 'BH',
    name: 'Bahrain'
  },
  {
    id: 'BD',
    name: 'Bangladesh'
  },
  {
    id: 'BB',
    name: 'Barbados'
  },
  {
    id: 'BY',
    name: 'Belarus'
  },
  {
    id: 'BE',
    name: 'Belgium'
  },
  {
    id: 'BZ',
    name: 'Belize'
  },
  {
    id: 'BJ',
    name: 'Benin'
  },
  {
    id: 'BM',
    name: 'Bermuda'
  },
  {
    id: 'BT',
    name: 'Bhutan'
  },
  {
    id: 'BO',
    name: 'Bolivia, Plurinational State of'
  },
  {
    id: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    id: 'BA',
    name: 'Bosnia and Herzegovina'
  },
  {
    id: 'BW',
    name: 'Botswana'
  },
  {
    id: 'BR',
    name: 'Brazil'
  },
  {
    id: 'IO',
    name: 'British Indian Ocean Territory'
  },
  {
    id: 'BN',
    name: 'Brunei Darussalam'
  },
  {
    id: 'BG',
    name: 'Bulgaria'
  },
  {
    id: 'BF',
    name: 'Burkina Faso'
  },
  {
    id: 'BI',
    name: 'Burundi'
  },
  {
    id: 'CV',
    name: 'Cabo Verde'
  },
  {
    id: 'KH',
    name: 'Cambodia'
  },
  {
    id: 'CM',
    name: 'Cameroon'
  },
  {
    id: 'CA',
    name: 'Canada'
  },
  {
    id: 'KY',
    name: 'Cayman Islands'
  },
  {
    id: 'CF',
    name: 'Central African Republic'
  },
  {
    id: 'TD',
    name: 'Chad'
  },
  {
    id: 'CL',
    name: 'Chile'
  },
  {
    id: 'CN',
    name: 'China'
  },
  {
    id: 'CX',
    name: 'Christmas Island'
  },
  {
    id: 'CC',
    name: 'Cocos (Keeling) Islands'
  },
  {
    id: 'CO',
    name: 'Colombia'
  },
  {
    id: 'KM',
    name: 'Comoros'
  },
  {
    id: 'CG',
    name: 'Congo'
  },
  {
    id: 'CD',
    name: 'Congo, the Democratic Republic of the'
  },
  {
    id: 'CK',
    name: 'Cook Islands'
  },
  {
    id: 'CR',
    name: 'Costa Rica'
  },
  {
    id: 'HR',
    name: 'Croatia'
  },
  {
    id: 'CU',
    name: 'Cuba'
  },
  {
    id: 'CW',
    name: 'Curaçao'
  },
  {
    id: 'CY',
    name: 'Cyprus'
  },
  {
    id: 'CZ',
    name: 'Czech Republic'
  },
  {
    id: 'CI',
    name: "Côte d'Ivoire"
  },
  {
    id: 'DK',
    name: 'Denmark'
  },
  {
    id: 'DJ',
    name: 'Djibouti'
  },
  {
    id: 'DM',
    name: 'Dominica'
  },
  {
    id: 'DO',
    name: 'Dominican Republic'
  },
  {
    id: 'EC',
    name: 'Ecuador'
  },
  {
    id: 'EG',
    name: 'Egypt'
  },
  {
    id: 'SV',
    name: 'El Salvador'
  },
  {
    id: 'GQ',
    name: 'Equatorial Guinea'
  },
  {
    id: 'ER',
    name: 'Eritrea'
  },
  {
    id: 'EE',
    name: 'Estonia'
  },
  {
    id: 'SZ',
    name: 'Eswatini'
  },
  {
    id: 'ET',
    name: 'Ethiopia'
  },
  {
    id: 'FK',
    name: 'Falkland Islands (Malvinas)'
  },
  {
    id: 'FO',
    name: 'Faroe Islands'
  },
  {
    id: 'FJ',
    name: 'Fiji'
  },
  {
    id: 'FI',
    name: 'Finland'
  },
  {
    id: 'FR',
    name: 'France'
  },
  {
    id: 'GF',
    name: 'French Guiana'
  },
  {
    id: 'PF',
    name: 'French Polynesia'
  },
  {
    id: 'GA',
    name: 'Gabon'
  },
  {
    id: 'GM',
    name: 'Gambia'
  },
  {
    id: 'GE',
    name: 'Georgia'
  },
  {
    id: 'DE',
    name: 'Germany'
  },
  {
    id: 'GH',
    name: 'Ghana'
  },
  {
    id: 'GI',
    name: 'Gibraltar'
  },
  {
    id: 'GR',
    name: 'Greece'
  },
  {
    id: 'GL',
    name: 'Greenland'
  },
  {
    id: 'GD',
    name: 'Grenada'
  },
  {
    id: 'GP',
    name: 'Guadeloupe'
  },
  {
    id: 'GU',
    name: 'Guam'
  },
  {
    id: 'GT',
    name: 'Guatemala'
  },
  {
    id: 'GG',
    name: 'Guernsey'
  },
  {
    id: 'GN',
    name: 'Guinea'
  },
  {
    id: 'GW',
    name: 'Guinea-Bissau'
  },
  {
    id: 'GY',
    name: 'Guyana'
  },
  {
    id: 'HT',
    name: 'Haiti'
  },
  {
    id: 'VA',
    name: 'Holy See'
  },
  {
    id: 'HN',
    name: 'Honduras'
  },
  {
    id: 'HK',
    name: 'Hong Kong'
  },
  {
    id: 'HU',
    name: 'Hungary'
  },
  {
    id: 'IS',
    name: 'Iceland'
  },
  {
    id: 'IN',
    name: 'India'
  },
  {
    id: 'ID',
    name: 'Indonesia'
  },
  {
    id: 'IR',
    name: 'Iran, Islamic Republic of'
  },
  {
    id: 'IQ',
    name: 'Iraq'
  },
  {
    id: 'IE',
    name: 'Ireland'
  },
  {
    id: 'IM',
    name: 'Isle of Man'
  },
  {
    id: 'IL',
    name: 'Israel'
  },
  {
    id: 'IT',
    name: 'Italy'
  },
  {
    id: 'JM',
    name: 'Jamaica'
  },
  {
    id: 'JP',
    name: 'Japan'
  },
  {
    id: 'JE',
    name: 'Jersey'
  },
  {
    id: 'JO',
    name: 'Jordan'
  },
  {
    id: 'KZ',
    name: 'Kazakhstan'
  },
  {
    id: 'KE',
    name: 'Kenya'
  },
  {
    id: 'KI',
    name: 'Kiribati'
  },
  {
    id: 'KP',
    name: "Korea, Democratic People's Republic of"
  },
  {
    id: 'KR',
    name: 'Korea, Republic of'
  },
  {
    id: 'XK',
    name: 'Kosovo'
  },
  {
    id: 'KW',
    name: 'Kuwait'
  },
  {
    id: 'KG',
    name: 'Kyrgyzstan'
  },
  {
    id: 'LA',
    name: "Lao People's Democratic Republic"
  },
  {
    id: 'LV',
    name: 'Latvia'
  },
  {
    id: 'LB',
    name: 'Lebanon'
  },
  {
    id: 'LS',
    name: 'Lesotho'
  },
  {
    id: 'LR',
    name: 'Liberia'
  },
  {
    id: 'LY',
    name: 'Libya'
  },
  {
    id: 'LI',
    name: 'Liechtenstein'
  },
  {
    id: 'LT',
    name: 'Lithuania'
  },
  {
    id: 'LU',
    name: 'Luxembourg'
  },
  {
    id: 'MO',
    name: 'Macao'
  },
  {
    id: 'MG',
    name: 'Madagascar'
  },
  {
    id: 'MW',
    name: 'Malawi'
  },
  {
    id: 'MY',
    name: 'Malaysia'
  },
  {
    id: 'MV',
    name: 'Maldives'
  },
  {
    id: 'ML',
    name: 'Mali'
  },
  {
    id: 'MT',
    name: 'Malta'
  },
  {
    id: 'MH',
    name: 'Marshall Islands'
  },
  {
    id: 'MQ',
    name: 'Martinique'
  },
  {
    id: 'MR',
    name: 'Mauritania'
  },
  {
    id: 'MU',
    name: 'Mauritius'
  },
  {
    id: 'YT',
    name: 'Mayotte'
  },
  {
    id: 'MX',
    name: 'Mexico'
  },
  {
    id: 'FM',
    name: 'Micronesia, Federated States of'
  },
  {
    id: 'MD',
    name: 'Moldova, Republic of'
  },
  {
    id: 'MC',
    name: 'Monaco'
  },
  {
    id: 'MN',
    name: 'Mongolia'
  },
  {
    id: 'ME',
    name: 'Montenegro'
  },
  {
    id: 'MS',
    name: 'Montserrat'
  },
  {
    id: 'MA',
    name: 'Morocco'
  },
  {
    id: 'MZ',
    name: 'Mozambique'
  },
  {
    id: 'MM',
    name: 'Myanmar'
  },
  {
    id: 'NA',
    name: 'Namibia'
  },
  {
    id: 'NR',
    name: 'Nauru'
  },
  {
    id: 'NP',
    name: 'Nepal'
  },
  {
    id: 'NL',
    name: 'Netherlands'
  },
  {
    id: 'NC',
    name: 'New Caledonia'
  },
  {
    id: 'NZ',
    name: 'New Zealand'
  },
  {
    id: 'NI',
    name: 'Nicaragua'
  },
  {
    id: 'NE',
    name: 'Niger'
  },
  {
    id: 'NG',
    name: 'Nigeria'
  },
  {
    id: 'NU',
    name: 'Niue'
  },
  {
    id: 'NF',
    name: 'Norfolk Island'
  },
  {
    id: 'MK',
    name: 'North Macedonia'
  },
  {
    id: 'MP',
    name: 'Northern Mariana Islands'
  },
  {
    id: 'NO',
    name: 'Norway'
  },
  {
    id: 'OM',
    name: 'Oman'
  },
  {
    id: 'PK',
    name: 'Pakistan'
  },
  {
    id: 'PW',
    name: 'Palau'
  },
  {
    id: 'PS',
    name: 'Palestine, State of'
  },
  {
    id: 'PA',
    name: 'Panama'
  },
  {
    id: 'PG',
    name: 'Papua New Guinea'
  },
  {
    id: 'PY',
    name: 'Paraguay'
  },
  {
    id: 'PE',
    name: 'Peru'
  },
  {
    id: 'PH',
    name: 'Philippines'
  },
  {
    id: 'PL',
    name: 'Poland'
  },
  {
    id: 'PT',
    name: 'Portugal'
  },
  {
    id: 'PR',
    name: 'Puerto Rico'
  },
  {
    id: 'QA',
    name: 'Qatar'
  },
  {
    id: 'RO',
    name: 'Romania'
  },
  {
    id: 'RU',
    name: 'Russian Federation'
  },
  {
    id: 'RW',
    name: 'Rwanda'
  },
  {
    id: 'RE',
    name: 'Réunion'
  },
  {
    id: 'BL',
    name: 'Saint Barthélemy'
  },
  {
    id: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    id: 'KN',
    name: 'Saint Kitts and Nevis'
  },
  {
    id: 'LC',
    name: 'Saint Lucia'
  },
  {
    id: 'MF',
    name: 'Saint Martin (French part)'
  },
  {
    id: 'PM',
    name: 'Saint Pierre and Miquelon'
  },
  {
    id: 'VC',
    name: 'Saint Vincent and the Grenadines'
  },
  {
    id: 'WS',
    name: 'Samoa'
  },
  {
    id: 'SM',
    name: 'San Marino'
  },
  {
    id: 'ST',
    name: 'Sao Tome and Principe'
  },
  {
    id: 'SA',
    name: 'Saudi Arabia'
  },
  {
    id: 'SN',
    name: 'Senegal'
  },
  {
    id: 'RS',
    name: 'Serbia'
  },
  {
    id: 'SC',
    name: 'Seychelles'
  },
  {
    id: 'SL',
    name: 'Sierra Leone'
  },
  {
    id: 'SG',
    name: 'Singapore'
  },
  {
    id: 'SX',
    name: 'Sint Maarten (Dutch part)'
  },
  {
    id: 'SK',
    name: 'Slovakia'
  },
  {
    id: 'SI',
    name: 'Slovenia'
  },
  {
    id: 'SB',
    name: 'Solomon Islands'
  },
  {
    id: 'SO',
    name: 'Somalia'
  },
  {
    id: 'SS',
    name: 'South Sudan'
  },
  {
    id: 'ES',
    name: 'Spain'
  },
  {
    id: 'LK',
    name: 'Sri Lanka'
  },
  {
    id: 'SD',
    name: 'Sudan'
  },
  {
    id: 'SR',
    name: 'Suriname'
  },
  {
    id: 'SJ',
    name: 'Svalbard and Jan Mayen'
  },
  {
    id: 'SE',
    name: 'Sweden'
  },
  {
    id: 'CH',
    name: 'Switzerland'
  },
  {
    id: 'SY',
    name: 'Syrian Arab Republic'
  },
  {
    id: 'TW',
    name: 'Taiwan, Province of China'
  },
  {
    id: 'TJ',
    name: 'Tajikistan'
  },
  {
    id: 'TZ',
    name: 'Tanzania, United Republic of'
  },
  {
    id: 'TH',
    name: 'Thailand'
  },
  {
    id: 'TL',
    name: 'Timor-Leste'
  },
  {
    id: 'TG',
    name: 'Togo'
  },
  {
    id: 'TK',
    name: 'Tokelau'
  },
  {
    id: 'TO',
    name: 'Tonga'
  },
  {
    id: 'TT',
    name: 'Trinidad and Tobago'
  },
  {
    id: 'TN',
    name: 'Tunisia'
  },
  {
    id: 'TR',
    name: 'Turkey'
  },
  {
    id: 'TM',
    name: 'Turkmenistan'
  },
  {
    id: 'TC',
    name: 'Turks and Caicos Islands'
  },
  {
    id: 'TV',
    name: 'Tuvalu'
  },
  {
    id: 'UG',
    name: 'Uganda'
  },
  {
    id: 'UA',
    name: 'Ukraine'
  },
  {
    id: 'AE',
    name: 'United Arab Emirates'
  },
  {
    id: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland'
  },
  {
    id: 'US',
    name: 'United States of America'
  },
  {
    id: 'UY',
    name: 'Uruguay'
  },
  {
    id: 'UZ',
    name: 'Uzbekistan'
  },
  {
    id: 'VU',
    name: 'Vanuatu'
  },
  {
    id: 'VE',
    name: 'Venezuela (Bolivarian Republic of)'
  },
  {
    id: 'VN',
    name: 'Viet Nam'
  },
  {
    id: 'VG',
    name: 'Virgin Islands (British)'
  },
  {
    id: 'VI',
    name: 'Virgin Islands (U.S.)'
  },
  {
    id: 'WF',
    name: 'Wallis and Futuna'
  },
  {
    id: 'EH',
    name: 'Western Sahara'
  },
  {
    id: 'YE',
    name: 'Yemen'
  },
  {
    id: 'ZM',
    name: 'Zambia'
  },
  {
    id: 'ZW',
    name: 'Zimbabwe'
  },
  {
    id: 'AX',
    name: 'Åland Islands'
  }
]
